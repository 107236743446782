import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import './Compare.css'

const TYPES = {
  'M3': {
    label: 'Model 3',
    variants: [{ code: 'M3', label: 'Napęd na tył', topSpeed: 201, acceleration: 6.1, range: 460, price: 206000, lowestPrice: 0 }, { code: 'M3LR', label: 'Long range', topSpeed: 201, acceleration: 4.7, range: 560, price: 226000, lowestPrice: 0 }]
  },
  'MY': {
    label: 'Model Y',
    variants: [{ code: 'MY', label: 'Napęd na tył', topSpeed: 217, acceleration: 6.9, range: 455, price: 224900, lowestPrice: 0 }, { code: 'M3LR', label: 'Long range', topSpeed: 217, acceleration: 5, range: 533, price: 260990, lowestPrice: 0 }, { code: 'M3P', label: 'Performance', topSpeed: 250, acceleration: 3.7, range: 514, price: 281990, lowestPrice: 0 }]
  },
  'MS': {
    label: 'Model S',
    variants: [{ code: 'MS', label: '', topSpeed: 250, acceleration: 3.2, range: 634, price: 449990, lowestPrice: 0 }, { code: 'MSP', label: 'Plaid', topSpeed: 322, acceleration: 2.1, range: 600, price: 519990, lowestPrice: 0 }]
  },
  'MX':  {
    label: 'Model X',
    variants: [{ code: 'MX', label: '', topSpeed: 250, acceleration: 3.9, range: 576, price: 484990, lowestPrice: 0 }, { code: 'MXP', label: 'Plaid', topSpeed: 262, acceleration: 2.6, range: 543, price: 554990, lowestPrice: 0 }]
  },
}

const DOMAIN = 'https://teslahub.pl'
const MODEL_IMAGE_PATH = `${DOMAIN}/assets/images/`

export default () => {
  const [selectedType, setSelectedType] = useState(null)
  
  return (
    <>
      <div class="container">
          {Object.keys(TYPES).map((type) => 
            <div class={`item ${selectedType != type && 'grayed-image'}`} onClick={() => setSelectedType(type)}>
                <span class="label"><b>{TYPES[type].label}</b></span>
                {selectedType == type ? <img class="model-img" src={`${MODEL_IMAGE_PATH}${type}c.png`}/> :
                <img class="model-img" onMouseOver={e=> e.currentTarget.src = `${MODEL_IMAGE_PATH}${type}a.png`} src={`${MODEL_IMAGE_PATH}${type}b.png`} onMouseOut={e=> e.currentTarget.src = `${MODEL_IMAGE_PATH}${type}b.png`} />}
            </div>
          )}
      </div>
      {selectedType && (
        <div class="main-table">
          {TYPES[selectedType].variants.map((variant) => (
            <div class="column">
              <span class="row"><b>{TYPES[selectedType].label} {variant.label}</b></span>
              <div class="row">
                <div class="details-table">
                  <div class="details-column">
                    <span class="row">Cena</span>
                    <span class="row">Vmax</span>
                    <span class="row">Przysp.</span>
                    <span class="row">Zasięg</span>
                  </div>
                  <div class="details-column">
                    <span class="row"><b>{variant.price} PLN</b></span>
                    <span class="row"><b>{variant.topSpeed} km/h</b></span>
                    <span class="row"><b>{variant.acceleration} s/100</b></span>
                    <span class="row"><b>{variant.range} km</b></span>
                  </div>
                  </div>
                </div>
              {/* <Button>Dodaj do<br/>powiadomień</Button> */}
            </div>
          ))}
        </div>
      )}
      
    </>
)}